import translationENCA from 'languages/en-CA.json';
import translationPSEUDO from 'languages/pseudo.json';

export const LANGUAGES = ['en-CA', 'pseudo'];
export const DEFAULT_LANGUAGE = LANGUAGES[0];

export const resources = {
  [LANGUAGES[0]]: translationENCA,
  [LANGUAGES[1]]: translationPSEUDO,
};
